.hoverable {
  cursor: pointer;
  transition: all 0.3s;
  padding: 3px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background-color: #badcff;
  }
}